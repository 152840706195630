import React from 'react'
import {useState, useEffect}  from 'react'


import classes from '../styles/chapter-item.module.sass'

import { Link } from "gatsby"
import { useMsal } from '../utility/auth/msalContext';

import {getCompletedExcerciseCount} from './progress-helper'


export const ChapterItem = ({ id, title, description, url, tasks }) => {


  const {progress} = useMsal()
  const [doneCount, setDoneCount] = useState(0)
  const [taskCount, setTaskCount] = useState(0)

  useEffect(() => {
      if(!progress) {
        console.debug("Failed to retrieve progress")
        return
    }

    let totalTasks = 0
    let tasksCompleted = 0

    for (var i = 0, len = tasks.length; i < len; i++) {
        const task = tasks[i]
        const exercises = task.node.htmlAst.children.filter((elem) => elem.tagName === "exercise")
        if(exercises.length > 0){
            totalTasks++

            const a = getCompletedExcerciseCount(id, task.node.frontmatter.id,progress, exercises)
            if (a === exercises.length)
                tasksCompleted++
        }

    }
    setDoneCount(tasksCompleted)
    setTaskCount(totalTasks)

}, [progress]);

    return (
      <Link to={url}>
        <div className={classes.item}>
            <div className={classes.header}>
              <div className={classes.leftheader}>
                <h2 className={classes.title}>{title}</h2>
              </div>
            </div>
            <div className={classes.textcontainer}>
              <p className={classes.text}>{description}</p>
              {(taskCount > 0) && <p className={classes.progress}>Completed: {doneCount}/{taskCount}</p>}
            </div>
        </div>
      </Link>
    )
}
